body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

input {
  user-select: all;
}

.MuiSlider-valueLabelOpen {
  &.MuiSlider-valueLabel {
    background-color: #fa6d0f;
    padding: 0.2rem 0.3rem;
    border-radius: 4rem;
  }
}

.MuiSlider-valueLabel {
  &.MuiSlider-valueLabel {
    background-color: #fa6d0f;
    padding: 0.2rem 0.3rem;
    border-radius: 4rem;
  }
}

.MuiSlider-root {
  color: #fa6d0f !important;
}

.MuiSlider-rail {
  background-color: #97979c !important;
}
