@property --myColor1 {
  syntax: "<color>";
  initial-value: rgba(0, 0, 0, 1);
  inherits: false;
}

@property --myColor2 {
  syntax: "<color>";
  initial-value: rgba(0, 15, 18, 1);
  inherits: false;
}

@property --myColor3 {
  syntax: "<color>";
  initial-value: rgba(0, 49, 62, 1);
  inherits: false;
}

@keyframes bgAnima {
  0% {
    --myColor1: rgba(0, 0, 0, 1);
    --myColor2: rgb(0, 17, 18);
    --myColor3: rgb(0, 86, 92);
  }
  25% {
    --myColor1: rgba(0, 0, 0, 1);
    --myColor2: rgb(0, 0, 18);
    --myColor3: rgb(0, 17, 92);
  }
  50% {
    --myColor1: rgba(0, 0, 0, 1);
    --myColor2: rgb(18, 0, 18);
    --myColor3: rgb(92, 0, 92);
  }
  75% {
    --myColor1: rgba(0, 0, 0, 1);
    --myColor2: rgb(18, 0, 0);
    --myColor3: rgb(92, 0, 0);
  }
  100% {
    --myColor1: rgba(0, 0, 0, 1);
    --myColor2: rgb(0, 17, 18);
    --myColor3: rgb(0, 86, 92);
  }
}

.bg {
  display: block;
  position: absolute !important;
  width: 100vw !important;
  height: 100vh !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 25 !important;
  background-color: #000000;
  background: linear-gradient(
    0deg,
    var(--myColor1) 0%,
    var(--myColor2) 53%,
    var(--myColor3) 100%
  );
  // transition: --myColor1 3s, --myColor2 3s, --myColor3 3s;
  animation: bgAnima 60s infinite;

  &.hide {
    display: none;
  }
}

.canvas {
  display: block;
  position: absolute !important;
  width: 100vw !important;
  height: 100vh !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 50 !important;

  &.hide {
    display: none;
  }
}
.hud1 {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("../../../../../../assets/images/hud-test.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  mix-blend-mode: screen;
  z-index: 99999999999;
}

.borg {
  position: fixed;
  display: none;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  z-index: 999;
  padding: 20px 20px 25px;
  box-sizing: border-box;

  &.on {
    display: flex;
  }

  &.hide {
    display: none;
  }

  .borg-title {
    font-family: "Orbitron", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 20px;
  }

  .borg-warning {
    width:100%;
    font-family: "Orbitron", sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 2px;
    color: orange;
    white-space: pre-wrap;
    text-align:center;
    margin-top:20px;
  }

  .borg-values {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .borg-value {
      width: 54px;
      height: 90px;
      flex-shrink: 0;
      text-align: center;
      background-color: rgb(0, 80, 185);
      padding: 5px 5px 10px;
      border-radius: 15px;
      margin: 5px;

      &.selected {
        background-color: rgba(255, 0, 255, 0.8);
      }

      .borg-value-number {
        font-size: 26px;
        color: white;
      }

      .borg-value-description {
        font-size: 12px;
        line-height: 13px;
        color: #ffffff;

        &.selected {
          color: #000000;
        }
      }
    }
  }
}

.odometer {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  font-family: "Orbitron", sans-serif;
  letter-spacing: 1px;

  &.hide {
    display: none;
  }

  .rpm {
    position: absolute;
    left: 50%;
    bottom: 220px;
    transform: translateX(-50%);
    font-size: 60px;
    text-align: center;
    color: #00aaff;
    padding: 10px 30px;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 15px #78eefd;
    -webkit-text-stroke: 1px #00e1ff;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    opacity: 1;

    .child {
      position: relative;
    }
  }

  .general-top {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: auto;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    opacity: 1;
    z-index: 999;

    .estimulo-title {
      position: absolute;
      top: -40px;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #00aaff;
      padding: 0px 0px 10px;
      letter-spacing: 2px;
      text-shadow: 0px 0px 2px #78eefd;
      -webkit-text-stroke: 3px #00aaff;
    }
    .estimulo-title-top {
      position: absolute;
      top: -40px;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #78eefd;
      padding: 0px 0px 10px;
      letter-spacing: 2px;
    }

    .general-data-top {
      pointer-events: all;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      width: auto;
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      padding: 10px 10px;
      box-sizing: border-box;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }

      .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 0px 0px;

        &.estimulo {
          font-size: 63px;
          line-height: 20px;
        }
      }

      .value {
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        color: #00aaff;
        padding: 20px 0px 5px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;

        &.freado {
          text-shadow: 2px 2px 1px #000000;
        }
      }
    }
  }

  .general-left-top {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    left: 20px;
    top: 20px;

    opacity: 1;
    z-index: 99999999999999;

    .general-data {
      float: left;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 5px 15px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 15px;

      &.icon {
        padding: 0px !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.music {
        padding: 0px !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        background-color: transparent;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }

      .title {
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        padding: 0px 0px 0px;

        &.icon {
          font-size: 29px;
          line-height: 29px;
        }

        &.music {
          font-size: 12px;
          line-height: 33px;
        }
      }
      .value {
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #00aaff;
        padding: 0px 0px 10px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;
      }
    }
  }

  .general-right-top {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    right: 20px;
    top: 20px;

    opacity: 1;
    z-index: 99999999999999;

    .general-data {
      float: left;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 5px 15px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 15px;

      &.icon {
        padding: 0px !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.ciclo {
        padding: 0px !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        background-color: transparent;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }

      .title {
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        padding: 0px 0px 0px;

        &.icon {
          font-size: 29px;
          line-height: 29px;
        }

        &.ciclo {
          font-size: 16px;
          line-height: 33px;
        }
      }
      .value {
        width: 100%;
        text-align: left;
        font-size: 18px;
        color: #00aaff;
        padding: 0px 0px 10px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;
      }
    }
  }

  .general-left {
    position: absolute;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 170px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    opacity: 1;
    z-index: 99999999999999;

    .general-data {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      padding: 5px 15px;
      box-sizing: border-box;

      .title {
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #ffffff;
        padding: 10px 0px 0px;
      }
      .value {
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #00aaff;
        padding: 0px 0px 10px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;
      }
    }
  }

  .general-right {
    position: absolute;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 170px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    opacity: 1;

    .general-data-right {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      padding: 5px 15px;
      box-sizing: border-box;

      .title {
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #ffffff;
        padding: 10px 0px 0px;
      }
      .value {
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #00aaff;
        padding: 0px 0px 10px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;

        &.time {
          font-size: 15px;
        }
      }
    }
  }

  .general-bottom {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 1000px;
    left: 50%;
    bottom: 95px;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    opacity: 1;

    .estimulo-title {
      position: absolute;
      top: -40px;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #00aaff;
      padding: 0px 0px 10px;
      letter-spacing: 2px;
      text-shadow: 0px 0px 2px #00aaff;
      -webkit-text-stroke: 3px #00aaff;
    }

    .estimulo-title-top {
      position: absolute;
      top: -40px;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #78eefd;
      padding: 0px 0px 10px;
      letter-spacing: 2px;
    }

    .general-data-bottom {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      width: 170px;
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0px 0px;
      box-sizing: border-box;

      .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 0px 0px;

        &.estimulo {
          font-size: 13px;
        }
      }

      .value {
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #00aaff;
        padding: 0px 0px 5px;
        letter-spacing: 2px;
        text-shadow: 0px 0px 1px #78eefd;
        -webkit-text-stroke: 1px #01e1ff;
      }
    }
  }
}
